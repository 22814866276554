@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --bg-color: #fff;
  --primary-gradient: #224AB1;
  --secondary-gradient: #3978C2;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', 'sans-serif';

  background: var(--bg-color);
  color: #000;

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.app {
  width: 100%;
  height: 100%;
}
