*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
:before,
:after {
  --tw-content: "";
}
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
body {
  margin: 0;
  line-height: inherit;
}
.mixo-badge[data-v-2391dbcb] {
  flex: 1;
  display: flex;
  margin-left: 90%;
  //position: absolute !important;
  float: right;
  justify-content: flex-end;
  display: inline-block !important;
  visibility: visible !important;
  z-index: 999 !important;
  top: auto !important;
  right: 30px !important;
  bottom: 14px !important;
  //left: 100px !important;
  color: #f7f9fa !important;
  background-color: rgb(186 104 200 / var(--tw-bg-opacity)) !important;
  border-radius: 8px !important;
  padding: 8px 10px 8px 8px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 18px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: 20pxauto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px #0000001a, 0 1px 3px #0000001a;
  cursor: pointer;
}
.flex-right {
  //margin-right: 10%;
  margin-top: 2%;
  //padding-left: 80%;
  //display: flex;
  justify-content: flex-end;
  visibility: visible !important;
  //overflow: visible !important;
  color: #0000;
  background-color: #0000;
  opacity: 10 !important;
}
.mixo-badge > img[data-v-2391dbcb] {
  margin-left: 100%;
  display: flex;
  justify-content: right;
  visibility: visible !important;
  opacity: 10 !important;
  vertical-align: middle !important;
  color: #fff;
  background-color: #ce73d8;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
// a {
//   color: inherit;
//   text-decoration: inherit;
// }
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
button,
[role="button"] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}
[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
  print-color-adjust: unset;
}
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type="checkbox"] {
  border-radius: 0;
}
[type="radio"] {
  border-radius: 100%;
}
[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type="file"] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}
*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.visible {
  visibility: visible;
}
.absolute {
  position: absolute;
  margin-top: 3%;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.top-8 {
  top: 2rem;
}
.left-1\/2 {
  left: 50%;
}
.right-1\/2 {
  right: 50%;
}
.top-full {
  top: 100%;
}
.right-full {
  right: 100%;
}
.top-2 {
  top: 0.5rem;
}
.right-3 {
  right: 0.75rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.-ml-3 {
  margin-left: -0.75rem;
}
.-mt-72 {
  margin-top: -18rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-14 {
  //margin-top: 3.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.-mr-3 {
  margin-right: -0.75rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-8 {
  margin-right: 2rem;
}
.\!mt-0 {
  margin-top: 0 !important;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.grid {
  display: grid;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.h-full {
  height: 100%;
}
.h-12 {
  height: 3rem;
}
.h-10 {
  height: 2.5rem;
}
.h-8 {
  height: 2rem;
}
.h-5 {
  height: 1.25rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-6 {
  height: 1.5rem;
}
.max-h-full {
  max-height: 100%;
}
.w-full {
  width: 100%;
}
.w-1\/2 {
  width: 50%;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-10 {
  width: 2.5rem;
}
.w-auto {
  width: auto;
}
.w-5 {
  width: 1.25rem;
}
.w-screen {
  width: 100vw;
}
.w-16 {
  width: 4rem;
}
.w-6 {
  width: 1.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.max-w-md {
  //display: flex;
  max-width: 30rem;
  //margin-right: 3%;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-sm {
  max-width: 24rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-grow {
  flex-grow: 1;
}
.translate-x-1\/3 {
  --tw-translate-x: 33.333333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/4 {
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.list-disc {
  list-style-type: disc;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  //align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-2 {
  gap: 0.5rem;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  //margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-t {
  border-top-width: 1px;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-primary {
  --tw-border-opacity: 1;
  border-color: #375056;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bg-primary\/10 {
  background-color: #ffc7271a;
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: #375056;
}
.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.\!bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: #375056 !important;
}
.\!bg-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(112 112 112 / var(--tw-bg-opacity)) !important;
}
.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}
.\!bg-brand-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
}
.\!bg-brand-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
}
.\!bg-emerald-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}
.\!bg-transparent {
  background-color: transparent !important;
}
.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.p-4 {
  padding: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pt-16 {
  //padding-top: 4rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-8 {
  //padding-top: 2rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.text-center {
  text-align: center;
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.font-black {
  font-weight: 900;
}
.font-extrabold {
  font-weight: 800;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-tight {
  line-height: 1.25;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.text-primary\/5 {
  color: #ffc7270d;
}
.text-primary\/10 {
  color: #ffc7271a;
}
.text-primary {
  //--tw-text-opacity: 1;
  color: #375056;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}
.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}
.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}
.text-brand-primary {
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}
.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}
.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}
.placeholder-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.placeholder-gray-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.placeholder-slate-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}
.placeholder-slate-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}
.placeholder-slate-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}
.opacity-50 {
  opacity: 0.5;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}
.hover\:\!bg-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(112 112 112 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: #375056 !important;
}
.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.hover\:\!bg-brand-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-brand-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-slate-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}
.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}
.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}
.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: #197278;
}
.focus\:border-brand-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 110 / var(--tw-border-opacity));
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: #375056;
}
.focus\:ring-primary\/75:focus {
  --tw-ring-color: #375056;
}
.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}
.focus\:ring-slate-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}
.focus\:ring-brand-primary\/75:focus {
  --tw-ring-color: rgb(255 0 110 / 0.75);
}
.focus\:ring-brand-secondary\/75:focus {
  --tw-ring-color: rgb(255 190 11 / 0.75);
}
.focus\:ring-emerald-500\/75:focus {
  --tw-ring-color: rgb(16 185 129 / 0.75);
}
.focus\:ring-slate-300\/75:focus {
  --tw-ring-color: rgb(203 213 225 / 0.75);
}
.focus\:ring-brand-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 0 110 / var(--tw-ring-opacity));
}
.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:ring-primary:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: #375056;
}
.active\:shadow-none:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.disabled\:\!bg-slate-300:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}
.disabled\:text-slate-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:-mt-32 {
    margin-top: -8rem;
  }
  .sm\:mt-12 {
    margin-top: 3rem;
  }
  .sm\:mt-16 {
    margin-top: 4rem;
  }
  .sm\:-mr-48 {
    margin-right: -12rem;
  }
  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem;
  }
  .sm\:-mr-32 {
    margin-right: -8rem;
  }
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:inline-flex {
    display: inline-flex;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:max-w-3xl {
    max-width: 48rem;
  }
  .sm\:max-w-lg {
    max-width: 32rem;
  }
  .sm\:max-w-xl {
    max-width: 36rem;
  }
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-divide-opacity));
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:pr-6 {
    padding-right: 1.5rem;
  }
  .sm\:pt-12 {
    //padding-top: 3rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pb-0 {
    padding-bottom: 0;
  }
  .sm\:pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem;
  }
  .sm\:text-center {
    text-align: center;
  }
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (min-width: 768px) {
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:-mr-16 {
    margin-right: -4rem;
  }
  .md\:-ml-16 {
    margin-left: -4rem;
  }
  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:flex {
    display: flex;
  }
  .md\:max-w-4xl {
    max-width: 56rem;
  }
  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:p-6 {
    padding: 1.5rem;
  }
  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .md\:leading-tight {
    line-height: 1.25;
  }
  .md\:leading-10 {
    line-height: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute;
  }
  .lg\:relative {
    position: relative;
  }
  .lg\:inset-y-0 {
    top: 0px;
    bottom: 0px;
  }
  .lg\:left-0 {
    left: 0px;
  }
  .lg\:right-0 {
    right: 0px;
  }
  .lg\:left-80 {
    left: 20rem;
  }
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:m-0 {
    margin: 0;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:flex {
    //display: flex;
  }
  .lg\:grid {
    display: grid;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:w-auto {
    width: auto;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:max-w-7xl {
    max-width: 80rem;
  }
  .lg\:max-w-none {
    max-width: none;
  }
  .lg\:max-w-xl {
    max-width: 36rem;
  }
  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:gap-6 {
    gap: 1.5rem;
  }
  .lg\:gap-24 {
    gap: 6rem;
  }
  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:py-48 {
    //padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
@media (min-width: 1280px) {
  .xl\:max-w-2xl {
    //max-width: 42rem;
  }
  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xl\:gap-12 {
    gap: 3rem;
  }
  .xl\:pl-12 {
    padding-left: 3rem;
  }
}
@media (min-width: 1536px) {
  .\32xl\:h-full {
    height: 100%;
  }
  .\32xl\:max-w-3xl {
    max-width: 34rem;
  }
  .\32xl\:max-w-none {
    max-width: none;
  }
  .\32xl\:gap-24 {
    gap: 6rem;
  }
}
html,
body,
#app {
  margin: 0;
  height: 100vh;
  padding: 0;
}
.mixo-badge[data-v-2391dbcb] {
  flex: 1;
  display: flex;
  margin-left: 90%;
  //position: absolute !important;
  float: right;
  justify-content: flex-end;
  display: inline-block !important;
  visibility: visible !important;
  z-index: 999 !important;
  top: auto !important;
  right: 30px !important;
  bottom: 14px !important;
  //left: 100px !important;
  color: #f7f9fa !important;
  background-color: #375056 !important;
  border-radius: 8px !important;
  padding: 8px 10px 8px 8px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 18px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: 20pxauto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px #0000001a, 0 1px 3px #0000001a;
  cursor: pointer;
}
.mixo-badge:hover {
  --tw-bg-opacity: 1 !important;
  background-color: #197278 !important;
}
// .mixo-badge > img[data-v-2391dbcb] {
//   display: inline-block !important;
//   visibility: visible !important;
//   opacity: 1 !important;
//   vertical-align: middle !important;
// }
ile-root:empty {
  display: none;
}
.ui-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ui-button > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.ui-button {
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  text-align: center;
  font-weight: 500;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}
.ui-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.ui-button-xs {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.ui-button-sm {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.ui-button-base,
.ui-button-md {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.ui-button-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.ui-button-primary {
  --tw-bg-opacity: 1 !important;
  background-color: #375056 !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(112 112 112 / var(--tw-bg-opacity)) !important;
}
.ui-button-primary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: #375056;
  --tw-ring-offset-width: 2px;
}
.ui-button-primary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(112 112 112 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: #375056 !important;
}
.ui-button-secondary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: #375056;
  --tw-ring-offset-width: 2px;
}
.ui-button-secondary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-secondary .ui-button-danger:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}
.ui-button-secondary .ui-button-danger:focus {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}
.ui-button-tertiary {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-tertiary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.ui-button-tertiary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
}
.ui-button-link {
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}
.ui-button-link:hover {
  --tw-text-opacity: 1;
  color: rgb(255 190 11 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.ui-button-link .ui-button-danger:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}
.ui-button-brand-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-brand-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}
.ui-button-brand-primary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 0 110 / 0.75);
  --tw-ring-offset-width: 2px;
}
.ui-button-brand-primary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-brand-primary:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.ui-button-brand-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-brand-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.ui-button-brand-secondary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 190 11 / 0.75);
  --tw-ring-offset-width: 2px;
}
.ui-button-brand-secondary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-brand-success {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-brand-success:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.ui-button-brand-success:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(16 185 129 / 0.75);
  --tw-ring-offset-width: 2px;
}
.ui-button-brand-success:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-outline {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  background-color: transparent !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-outline:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.ui-button-outline:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 190 11 / 0.75);
  --tw-ring-offset-width: 2px;
}
.ui-button-outline:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-tertiary {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-button-tertiary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}
.ui-button-tertiary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(203 213 225 / 0.75);
  --tw-ring-offset-width: 2px;
}
.ui-button-tertiary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ui-alert-danger[data-v-3dfe414e] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.ui-alert-danger .ui-alert-icon[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}
.ui-alert-danger .ui-alert-title[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}
.ui-alert-danger .ui-alert-content[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}
.ui-alert-danger .ui-alert-details[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}
.ui-alert-info[data-v-3dfe414e] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.ui-alert-info .ui-alert-icon[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}
.ui-alert-info .ui-alert-title[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}
.ui-alert-info .ui-alert-content[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}
.ui-alert-info .ui-alert-details[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}
.ui-alert-success[data-v-3dfe414e] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.ui-alert-success .ui-alert-icon[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}
.ui-alert-success .ui-alert-title[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}
.ui-alert-success .ui-alert-content[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}
.ui-alert-success .ui-alert-details[data-v-3dfe414e] {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}
.user-avatar[data-v-3a9a377e] {
  margin: 0.5rem 0.25rem;
  display: block;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: #375056;
}
@media (min-width: 640px) {
  .user-avatar[data-v-3a9a377e] {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: 3.5rem;
    width: 3.5rem;
  }
}
.note-text[data-v-ec2bb61a] {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 1024px) {
  .note-text[data-v-ec2bb61a] {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
